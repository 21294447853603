import moment from 'moment-timezone'

/**
 * Format retribution with corporate commission
 * If corporate commission is formatted (not between 0 and 1), do the complete calculation
 * @param   {Number} retribution
 * @param   {Number} commission
 * @returns {Number}
 */
function formatWithCommission(retribution, commission, missionCreatedAt) {
  const isUsingCorporateFeesBefore20221001 =
    missionCreatedAt && moment(missionCreatedAt).isBefore(moment('2022-10-01'))

  if (isUsingCorporateFeesBefore20221001) {
    return commission < 1
      ? retribution + retribution * commission
      : retribution + (retribution * commission) / 100.0
  }

  const retributionWithCommission =
    commission < 1 ? retribution / (1.0 - commission) : retribution / (100 - commission)

  return parseFloat(retributionWithCommission.toFixed(2))
}

/* eslint-disable import/prefer-default-export */
export { formatWithCommission }
